import React from "react"
import { Helmet } from "react-helmet"

const RedirectPage = ({ pageContext }: any) => {
  const { redirectTo } = pageContext

  return (
    <Helmet>
      <meta http-equiv="refresh" content={`0;url=${redirectTo}`} />
      <meta name="robots" content="noindex" />
    </Helmet>
  )
}

export default RedirectPage
